<template>
  <main class="flex flex-wrap flex-col">
    <div class="w-full h-full">
      <slot />
    </div>
  </main>
</template>

<script setup lang="ts">

</script>
