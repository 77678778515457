<template>
  <custom-button
    v-show="y>250"
    size="none"
    class="fixed w-[38px] md:w-[60px] h-[38px] md:h-[60px] flex items-center justify-center right-[20px] z-[99]"
    :class="appStore.scrollToTopBottom"
    pill
    @click="scrollToTop"
  >
    <nuxt-icon
      name="common/arrows/chevron-up"
      class="text-xl"
    />
  </custom-button>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import { scrollToTop } from '~/common/helpers/scrolls'
import { useAppStateStore } from '~/store/app'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const { y } = useWindowScroll()

const appStore = useAppStateStore()

</script>
