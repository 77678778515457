<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { FEEDBACK_WIDGET_OPTIONS } from '~/modules/widget/constants/feedback-widget/FeedbackWidget.options'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const isFeedbackWidgetModalOpen = ref(false)

const options = computed(() => [
  {
    label: t(`feedback.options.${FEEDBACK_WIDGET_OPTIONS.suggestIdea}`),
    link: 'https://t.me/estatyx_bot',
    icon: 'common/idea',
    disabled: false,
  },
  {
    label: t(`feedback.options.${FEEDBACK_WIDGET_OPTIONS.dataError}`),
    link: 'https://t.me/estatyx_data_report_bot',
    icon: 'common/search-document',
    disabled: false,
  },
  {
    label: t(`feedback.options.${FEEDBACK_WIDGET_OPTIONS.serviceError}`),
    link: 'https://t.me/estatyx_bot',
    icon: 'common/warning-triangle',
    disabled: false,
  },
])

const openFeedbackWidgetModal = () => {
  if (!props.disabled) {
    isFeedbackWidgetModalOpen.value = true
  }
}

</script>

<template>
  <div
    class="fixed bg-primary-light text-primary hover:text-white hover:bg-primary active:bg-primary-dark active:text-white bottom-[20%] right-0 cursor-pointer rotate-180 px-[2px] py-[20px] rounded-tr-[20px] rounded-br-[20px]"
    style="writing-mode: vertical-rl;"
    :class="[{'!bg-neutral-100 !text-neutral-150 !cursor-not-allowed': disabled}, 'base-widget']"
    @click="openFeedbackWidgetModal"
  >
    {{ t('feedback.label') }}
  </div>
  <modal-or-bottom-sheet
    v-model="isFeedbackWidgetModalOpen"
    :modal-props="{size: 'w-[375px]', hideFooter: true, bodyClass: 'pt-0'}"
  >
    <template #header>
      {{ t('feedback.label') }}
    </template>
    <div class="flex flex-col gap-3">
      <custom-button
        v-for="option in options"
        :key="option.label"
        :href="option.link"
        :disabled="option.disabled"
        target="_blank"
        class="py-[19px] !rounded-[19px] w-full flex text-start items-center font-semibold"
        @click="isFeedbackWidgetModalOpen = false"
      >
        <template #prefix>
          <nuxt-icon
            filled
            :name="option.icon"
            class="text-lg"
          />
        </template>
        {{ option.label }}
      </custom-button>
    </div>
  </modal-or-bottom-sheet>
</template>
